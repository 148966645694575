import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ['checkboxes', 'test']

    a = this.testTarget.style.display = "none";

    displayMailList() {
        let radioButton = document.getElementById('article_private_true')
        if (radioButton.checked) {
            this.checkboxesTarget.style.display = "none";
            this.testTarget.style.display = "block";
        }
    };

    hideMailList() {
        let radioButton = document.getElementById('article_private_false')
        if (radioButton.checked) {
            this.checkboxesTarget.style.display = "block";
            this.testTarget.style.display = "none";
        }
    };
}