import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["input"];

    resize(event){
        event.target.style.height = 'auto';
        event.target.style.height =  `${event.target.scrollHeight}px`;
    }

}