import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [ 'checkboxes', 'checkboxesCount']


    connect(){
        this.setCountCheckboxes();
    }
    toggleCheckboxes(event) {
        let checkedStatus = event.target.checked

        this.checkboxesTargets.forEach(function (target) {
            target.checked = checkedStatus
        });
    };

    get selectedCheckboxes() {
        return this.checkboxesTargets.filter((checkbox) => checkbox.checked);
    };

    setCountCheckboxes() {
        const count = this.selectedCheckboxes.length;
        this.checkboxesCountTarget.innerHTML = `${count} selezionati`
    };



}