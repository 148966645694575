/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"

// Stimulus setup .............................................................
import "stimulus"

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
// ............................................................................


// Bootsrap setup .............................................................
import "bootstrap"
import '../stylesheet/application.scss'
// ............................................................................

// Trix editor setup .............................................................
import 'trix'
// ............................................................................

// Font Awesome setup .............................................................
import "@fortawesome/fontawesome-free/js/all";
// ............................................................................

// Select2 setup .............................................................
import "select2/dist/js/select2.min"

$(document).ready(function () {
        $('select.select2').each(function (i, element) {
            $(element).select2({
                ajax: {
                    // L'url viene dal data della select
                    dataType: 'json',
                    data: function (params) {
                        var query = {
                            q: params.term,
                            types: $(element).data('types')
                        }
                        return query;
                    },
                    processResults: function (data, params) {
                        // Trasformo il json nel formato select2
                        $.map(data, function (obj) {
                            // Gestisco sia il caso di liste a un livello o nested (con children)
                            // In quel caso non devo sostituire il text perchè è l'etichetta del separatore
                            obj['text'] = obj['text'] || obj[$(element).data('label')];
                            $.map(obj['children'], function (children) {
                                children['text'] = children[$(element).data('label')];
                            });
                        });
                        return {results: data};
                    },
                },
                minimumInputLength: 3,
                allowClear: true
            })
        });

        $(".show-role").click(function () {
            $("#roles_head").slideToggle("slow");
            $("#roles_body").slideToggle("slow");
        });
    }
);
// ............................................................................

// Image setup ................................................................
require.context('image', true)
require.context('delete_user_guide', true)
// ............................................................................

Rails.start()
