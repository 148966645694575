import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [ 'checkboxes']

    toggleCheckboxes(event) {
        let checkedStatus = event.target.checked

        this.checkboxesTargets.forEach(function (target) {
            target.checked = checkedStatus
        });
    };
}